import { gql } from "@apollo/client";

export const MY_TASKS_QUERY = gql`
  query MY_TASKS_QUERY {
    myTasks {
      tasks {
        id
        title
        assignments {
          id
          firstName
          lastName
        }
        process {
          deleted
          id
          processType
          asset {
            id
            loanNumber
            activeProcess {
              id
            }
          }
        }
        activity {
          deleted
          id
          asset {
            id
            loanNumber
          }
        }
        blocking
        createdAt
        completedAt
        description
        deadlineAt
        milestone
        dueByMilestone
        owner {
          id
          firstName
          lastName
        }
      }
      taskFlows {
        id
        name
        assignments {
          id
          firstName
          lastName
        }
        activity {
          deleted
          id
          asset {
            id
            loanNumber
          }
        }
        process {
          deleted
          id
          processType
          asset {
            id
            loanNumber
            activeProcess {
              id
            }
          }
        }
        blocking
        created_at
        startDate
        completedAt
        dueDate
        milestoneID
        owner {
          firstName
          lastName
        }
        subtasks {
          id
          name
          completedAt
          dueDate
          assignments {
            id
            firstName
            lastName
          }
          process {
            deleted
            id
            asset {
              id
              loanNumber
              activeProcess {
                id
              }
            }
          }
        }
      }
    }

    
  }
`;