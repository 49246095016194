import React from "react";
import Modal from "../../styled/Modal/Modal";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import Typography from "../../styled/Typography/Typography";

const StyledBox = styled(Box)`
  border-radius: 12px;
  width: 100%;
  padding: 2.5%;
  gap: 2.5%;
  margin: 2.5% 0;
  background-color: ${({ theme }) => theme.themeColor.sectionFill};
`;
const Row = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2.5%;
  width: 100%;
`;

export default function FilterAssetTimeline({ onClose, open, setFilters, setNumFilters, filters }) {
  const theme = useTheme();

  const getFilterCheckboxes = (section) => {
    return filters.map((filter) => {
      if (filter.section === section) {
        return (
          <Checkbox
            key={filter.value}
            checked={filter.checked}
            label={filter.name}
            onClick={() => onCheckboxClick(filter.value)}
          />
        );
      }
      return null;
    });
  };

  const onCheckboxClick = (value) => {
    const newFilters = filters.map((filter) => {
      return value === filter.value
        ? { ...filter, checked: !filter.checked }
        : { ...filter };
    })
    setFilters(newFilters);

    let numFilters = newFilters.reduce((acc, curr) => {
      if (!curr.checked) acc++;
      return acc;
    }, 0);
    if (numFilters === 0) numFilters = "0"
    setNumFilters(numFilters)
  };

  const onCategoryClick = (section) => {
    const newFilters = filters.map((filter) => {
      return filter.section === section
        ? { ...filter, checked: !filter.checked }
        : { ...filter };
    });
    setFilters(newFilters);
  
    let numFilters = newFilters.reduce((acc, curr) => {
      if (!curr.checked) acc++;
      return acc;
    }, 0);
  
    if (numFilters === 0) numFilters = "0";
    setNumFilters(numFilters);
  };
  
  return (
    <Modal onClose={onClose} open={open} height="75vh" width="50vw">
      <StyledBox spacing={2} theme={theme}>
        <Row>
          <Checkbox 
            checked={filters.some(filter => filter.section === 'comment' && filter.checked)}
            onClick={() => onCategoryClick('comment')}
          />
          <Typography
            variant="h6"
            sx={{ cursor: 'pointer', color: theme.themeColor.oppositeBody }}
            onClick={() => onCategoryClick('comment')}
          >
              Comments
          </Typography>
        </Row>
        <Grid>
          { getFilterCheckboxes('comment') }
        </Grid>

      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Row>
          <Checkbox 
            checked={filters.some(filter => filter.section === 'task' && filter.checked)}
            onClick={() => onCategoryClick('task')}
          />
          <Typography
            variant="h6"
            sx={{ cursor: 'pointer', color: theme.themeColor.oppositeBody }}
            onClick={() => onCategoryClick('task')}
          >
            Tasks
          </Typography>
        </Row>
        <Grid>
          { getFilterCheckboxes('task') }
        </Grid>
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Row>
          <Checkbox 
            checked={filters.some(filter => filter.section === 'hold' && filter.checked)}
            onClick={() => onCategoryClick('hold')}
          />
          <Typography
            variant="h6"
            sx={{ cursor: 'pointer', color: theme.themeColor.oppositeBody }}
            onClick={() => onCategoryClick('hold')}
          >
              Holds
          </Typography>
        </Row>
        <Grid>
          { getFilterCheckboxes('hold') }
        </Grid>
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Row>
          <Checkbox 
            checked={filters.some(filter => filter.section === 'milestone' && filter.checked)}
            onClick={() => onCategoryClick('milestone')}
          />
          <Typography
            variant="h6"
            sx={{ cursor: 'pointer', color: theme.themeColor.oppositeBody }}
            onClick={() => onCategoryClick('milestone')}
          >
            Milestones
          </Typography>
        </Row>
        <Grid>
          { getFilterCheckboxes('milestone') }
        </Grid>
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Row>
          <Checkbox 
            checked={filters.some(filter => filter.section === 'process' && filter.checked)}
            onClick={() => onCategoryClick('process')}
          />
          <Typography
            variant="h6"
            sx={{ cursor: 'pointer', color: theme.themeColor.oppositeBody }}
            onClick={() => onCategoryClick('process')}
          >
            Process Activity
          </Typography>
        </Row>
        <Grid>
          { getFilterCheckboxes('process') }
        </Grid>
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Typography variant="h6"
          sx={{ color: theme.themeColor.oppositeBody }}
        >
          Documents
        </Typography>
        { getFilterCheckboxes('document') }
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Typography
          variant="h6"
          sx={{ color: theme.themeColor.oppositeBody }}
        >
          Forms
        </Typography>
        { getFilterCheckboxes('form') }
      </StyledBox>

      <StyledBox spacing={2} theme={theme}>
        <Typography
          variant="h6"
          sx={{ color: theme.themeColor.oppositeBody }}  
        >
          TLDR
        </Typography>
        { getFilterCheckboxes('tldr') }
      </StyledBox>

    </Modal>
  )
}