import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { MessageContext } from "../../context/MessageContext";
import { MilestoneTokenContext } from "../../context/MilestoneTokenContext";
import { useMutation } from "@apollo/client";
import Typography from "../../styled/Typography/Typography";
import { Box } from "@mui/material";
import Button from "../../styled/Button/Button";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";
import { ASSET_QUERY } from "../../apollo/queries/assetQuery";
import { TASKS_QUERY } from "../../apollo/queries/tasksQuery";
import { HOLDS_QUERY } from "../../apollo/queries/holdsQuery";
import { GET_MILESTONE_CLOSED_TOKEN } from "../../apollo/queries/getMilestoneClosedToken";
import { CREATE_NOTIFICATION } from "../../apollo/mutations/createNotification";
import { GET_NOTIFICATIONS } from "../../apollo/queries/getNotifications";
import { useTheme } from "@emotion/react";
import { AssetContext } from "../../context/AssetContext";

const TimerContainer = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 150px;
  max-width: 200px;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  background: ${({ theme }) => `${rgba(theme.themeColor.sectionFill, 0.6)} !important`};
  backdrop-filter: blur(20px);
  border: 1px solid red;
  border-radius: 5px;
  margin-left: 20px;
`;

const UndoButton = styled(Button)`
  width: auto;
  border: none !important;
  background: inherit !important;
  box-shadow: none !important;
  color: ${({ theme }) => theme.themeColor.brandPrimaryRed};
  display: flex;
  justify-content: space-between;
`;

export default function MilestoneTimer({ client, activeProcess }) {
  const { asset } = useContext(AssetContext);
  const { milestoneToken, setMilestoneToken } = useContext(MilestoneTokenContext);
  const initialExpiration = Math.floor((new Date(+milestoneToken.expiresAt).getTime() - new Date().getTime()) / 1000);
  const [timeLeft, setTimeLeft] = useState(initialExpiration);

  const cancelTokenSourceRef = useRef(null);
  const intervalIdRef = useRef(null);
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  useEffect(() => {
    // Start the countdown interval
    intervalIdRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(intervalIdRef.current);
          executeApiCall();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup interval when component unmounts
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  const executeApiCall = async () => {
    cancelTokenSourceRef.current = axios.CancelToken.source();
    try {
      const form = new FormData();
      form.append('assetIDs', [asset.id]);
      form.append('tokenID', milestoneToken.id)
      await axios.post(
        `${process.env.REACT_APP_NEXT_IMPORT_API_URL}/api/calculate-loan-status`,
        form,
        { cancelToken: cancelTokenSourceRef.current?.token }
      );
      await createNotification({
        variables: {
          input: {
            message: `Milestone ${activeProcess.stepID} has been closed for asset #${asset.loanNumber}`,
            type: 'Milestone-closed'
          }
        }
      })
      addMessage({ message: 'The loan status for this asset has been updated.' })
      await createNotification({
        variables: {
          input: {
            message: `Loan status for asset #${asset.loanNumber} has been updated.`,
            type: 'LoanStatus-updated'
          }
        }
      })

      await client.refetchQueries({
        include: [
          "active",
          { query: ASSET_QUERY, variables: { assetID: asset.id } },
          { query: EVENTS_QUERY, variables: { milestoneID: activeProcess.stepID, processID: activeProcess.id } },
          { query: GET_MILESTONE_CLOSED_TOKEN, variables: { assetID: asset.id } },
          { query: TASKS_QUERY, variables: { processID: activeProcess.id } },
          { query: HOLDS_QUERY, variables: { processID: activeProcess.id } },
          { query: GET_NOTIFICATIONS }
        ]
      });
      setMilestoneToken(null);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('API call cancelled');
      } else {
        console.error('API call error:', error);
      }
    }
  };

  const handleUndo = async () => {
    if (cancelTokenSourceRef.current) {
      cancelTokenSourceRef.current.cancel();
    }
    // Clear the interval to stop the countdown
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    setTimeLeft(initialExpiration);

    const undoMilestoneData = new FormData();
    undoMilestoneData.append('token', milestoneToken.token);
    undoMilestoneData.append('tokenID', milestoneToken.id);

    await axios.post(`${process.env.REACT_APP_NEXT_IMPORT_API_URL}/api/undo-milestone-closed`, undoMilestoneData);

    const prevStepIndex = activeProcess.processSteps.findIndex(step => step.stepID === activeProcess.stepID) - 1;
    const prevStep = activeProcess.processSteps[prevStepIndex];

    addMessage({ message: `Milestone ${prevStep.stepID} has been re-opened.` })
    await createNotification({
      variables: {
        input: {
          message: `Milestone ${prevStep.stepID} has been re-opened for asset #${asset.loanNumber}`,
          type: 'Milestone-reopened'
        }
      }
    })

    await client.refetchQueries({
      include: [
        "active",
        { query: ASSET_QUERY, variables: { assetID: asset.id } },
        { query: EVENTS_QUERY, variables: { milestoneID: prevStep.stepID, processID: activeProcess.id } },
        { query: GET_MILESTONE_CLOSED_TOKEN, variables: { assetID: asset.id } },
        { query: TASKS_QUERY, variables: { processID: activeProcess.id } },
        { query: HOLDS_QUERY, variables: { processID: activeProcess.id } },
        { query: GET_NOTIFICATIONS }
      ]
    });
    setMilestoneToken(null);
  };

  // Calculating minutes and seconds for display
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <TimerContainer>
      <Typography number={true} sx={{ color: theme.themeColor.brandPrimaryRed }}>
        {String(minutes).padStart(2, '0')}:
        {String(seconds).padStart(2, '0')}
      </Typography>
      <UndoButton theme={theme} variant="secondary" onClick={handleUndo}>
        <Typography sx={{fontWeight: 'bold'}}>UNDO</Typography>
      </UndoButton>
    </TimerContainer>
  );
}
