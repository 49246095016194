export const NONCONTROLLABLE_STEPS = [
  'Answer Period',
  'Mediation Scheduled',
  'Order of Reference Received',
  'Referee Oath Received',
  'Judgment Hearing Scheduled',
  'Judgment Entered',
  'Presale Redemption Period',
  'FC Sale Held',
  'Post Sale Redemption Expired'
];
