import React, { useContext } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import CloseMilestone from '../CloseMilestone/CloseMilestone';
import { useTheme } from '@emotion/react';
import SelectMilestone from './SelectMilestone';
import MilestoneTimer from './MilestoneTimer';
import { MilestoneTokenContext } from '../../context/MilestoneTokenContext';

const StyledContainer = styled(Box)`
  width: 100%;
  color: ${({ theme }) => theme.themeColor.bodyMain};
  z-index: 1;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default function CurrentMilestone({
  milestoneID,
  milestones,
  activeStep,
  hasBlockingTask,
  hasOpenHolds,
  readOnly,
  originalFC,
  setScreen,
  activeProcess,
  setActiveProcess,
  client,
}) {
  const theme = useTheme();
  const { milestoneToken } = useContext(MilestoneTokenContext);

  return (
    <StyledContainer theme={theme}>
      <SelectMilestone
        milestones={milestones}
      />
      {!readOnly &&
          <CloseMilestone
            originalFC={originalFC}
            activeProcess={activeProcess}
            milestoneID={milestoneID}
            milestones={milestones}
            activeStep={activeStep}
            hasBlockingTask={hasBlockingTask}
            hasOpenHolds={hasOpenHolds}
            setScreen={setScreen}
            setActiveProcess={setActiveProcess}
          />}
      { milestoneToken &&
        <MilestoneTimer client={client} activeProcess={activeProcess} />
      }
    </StyledContainer>
  )
}