import React, { createContext, useState } from "react";

export const AssetContext = createContext(null);

export default function AssetProvider({ children }) {
  // Current asset open in the page and list of users and teams with access to it
  const [asset, setAsset] = useState(null);
  const [portfolioUsers, setPortfolioUsers] = useState([]);

  // Current milestone that the asset process is in
  const [currentMilestone, setCurrentMilestone] = useState(null);

  const [selectedProcess, setSelectedProcess] = useState(null);
  return (
    <AssetContext.Provider value={{ 
      asset,
      setAsset,
      portfolioUsers,
      setPortfolioUsers,
      currentMilestone,
      setCurrentMilestone,
      selectedProcess,
      setSelectedProcess
    }}>
      {children}
    </AssetContext.Provider>
  )
}