import React, { useContext, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, MenuItem, Select, Stack } from '@mui/material';
import Modal from '../../styled/Modal/Modal';
import { TextInput } from '../../styled/TextInput/TextInput';
import { ALL_STATES, JUDICIAL } from '../../utilities/genericSteps';
import Typography from '../../styled/Typography/Typography';
import Button from '../../styled/Button/Button';
import { useMutation } from '@apollo/client';
import { CREATE_INVITATION } from './LawfirmsList';
import { UPDATE_TEAM_INFO } from '../../apollo/mutations/updateTeamInfo';
import { UPDATE_CONTACT_EMAILS } from '../../apollo/mutations/updateContactEmails';
import { MessageContext } from '../../context/MessageContext';
import { isValidEmail } from '../../utilities';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

const AddressHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddIcon = styled(AddCircleIcon)`
  color: ${props => props.theme.themeColor.brandPrimaryBlue};
  cursor: pointer;
  align-self: center;
`;

const RemoveIcon = styled(RemoveCircleOutlineIcon)`
  color: ${props => props.theme.themeColor.brandPrimaryRed};
  cursor: pointer;
  align-self: center;
`

function getStateEmailDifferences(specificEmails, prevData) {
  const result = {};

  Object.keys(specificEmails).forEach(state => {
    const stateData = specificEmails[state];
    const prevStateData = prevData[state];

    if (!prevStateData) {
      result[state] = stateData;
      return;
    }

    const keysToCompare = new Set([
      ...Object.keys(stateData),
      ...Object.keys(prevStateData)
    ]);

    let differs = false;
    for (const key of keysToCompare) {
      if (stateData[key] !== prevStateData[key]) {
        differs = true;
        break;
      }
    }
  
    if (differs)
      result[state] = stateData;
  });

  return result;
}

function hasDiffAdmins(prevAdmins, admins) {
  if (!prevAdmins || !prevAdmins.length) return { toAdd: admins, toDelete: [] };

  const prev = [...prevAdmins].sort((a, b) => a.email.localeCompare(b.email));
  const current = [...admins].sort((a, b) => a.email.localeCompare(b.email));

  return JSON.stringify(prev) !== JSON.stringify(current)
}

// eslint-disable-next-line 
const urlRegEx = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
const URL_REGEXP = new RegExp(urlRegEx);

const validateUrl = url => {
  URL_REGEXP.lastIndex = 0;
  return URL_REGEXP.test(url);
};

const LawfirmInviteModal = ({ open, handleClose, formData }) => {
  const [companyName, setCompanyName] = useState(formData?.name || '');
  const [officeAddresses, setOfficeAddresses] = useState(formData?.officeAddresses || [{ address: '', city: '', county: '', postal: '', state: '' }]);
  const [website, setWebsite] = useState(formData?.website || '');
  const [administrators, setAdministrators] = useState(formData?.administrators || [{firstName: '', lastName: '', email: ''}]);
  const [selectedStates, setSelectedStates] = useState(formData?.states || []);
  const [stateSpecificEmails, setStateSpecificEmails] = useState(formData?.contacts || {});
  const { addMessage } = useContext(MessageContext);
  const theme = useTheme();

  const allStateOptions = ALL_STATES.map((state) => ({ text: state, value: state }));
  const dropdownValues = [{ text: 'All States', value: 'all' }].concat(allStateOptions);

  const [createInvitation, { loading }] = useMutation(CREATE_INVITATION, {
    awaitRefetchQueries: true,
    refetchQueries: "all"
  });

  const [updateTeamInfo] = useMutation(UPDATE_TEAM_INFO, {
    awaitRefetchQueries: true,
    refetchQueries: "all"
  });

  const [updateContactEmails] = useMutation(UPDATE_CONTACT_EMAILS, {
    awaitRefetchQueries: true,
    refetchQueries: "all"
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
    case 'name':
      setCompanyName(value);
      break;
    case 'website':
      setWebsite(value);
      break;
    default:
      break;
    }
  }

  const handleStateChange = (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes('all')) {
      // If all states are already selected, then unselect all states
      if (selectedStates.length === allStateOptions.length) setSelectedStates([]);
      else setSelectedStates(allStateOptions.map((state) => state.value));
    } else {
      setSelectedStates(value);
    }
  }

  const handleStateSpecificEmails = (state) => {
    const newStateSpecificEmails = { ...stateSpecificEmails };
    newStateSpecificEmails[state] = {
      ...newStateSpecificEmails[state],
      checked: !stateSpecificEmails[state]?.checked
    }

    setStateSpecificEmails(newStateSpecificEmails);
  }

  const formValidation = () => {
    if (!companyName) {
      addMessage({ type: 'error', message: 'Please fill all required fields.' });
      return false;
    }
    if (officeAddresses.length === 0
      || officeAddresses.some((office) =>
        office.address.length === 0 || office.city.length === 0 || office.state.length === 0 || office.state.length > 2 || office.postal.length === 0)
    ) {
      addMessage({ type: 'error', message: 'Please fill all required fields.' });
      return false;
    }
    if (administrators.length === 0
      || administrators.some((admin) =>
        admin.firstName?.length === 0 || admin.lastName?.length === 0 || admin.email?.length === 0)
    ) {
      addMessage({ type: 'error', message: 'Please fill all required fields.' });
      return false;
    }
    if (selectedStates.length === 0) {
      addMessage({ type: 'error', message: 'At least one state must be selected.' });
      return false;
    }
    if (website && !validateUrl(website)) {
      addMessage({ type: 'error', message: 'Please enter a valid website URL.' });
      return false;
    }
    return true
  }

  const onChangeSpecificStateEmails = (state, type, email) => {
    const newStateSpecificEmails = { ...stateSpecificEmails };
    newStateSpecificEmails[state] = {
      ...newStateSpecificEmails[state],
      [type === 'default' ? 'defaultEmail' : 'nonDefaultEmail']: email
    }

    setStateSpecificEmails(newStateSpecificEmails);
  }

  const handleSubmit = async () => {
    if (!formValidation()) return;

    // Test if admin emails are valid
    if (administrators.some((admin) => !isValidEmail(admin.email))) {
      addMessage({ type: 'error', message: 'Please ensure that all administrator email addresses are accurate.' });
      return;
    }

    const specificEmails = Object.keys(stateSpecificEmails).map((state) => {
      const stateData = stateSpecificEmails[state];
      return {
        state: state,
        defaultEmail: stateData.defaultEmail,
        nonDefaultEmail: stateData.nonDefaultEmail
      }
    });

    // Validate specific emails
    for (const state in stateSpecificEmails) {
      const stateData = stateSpecificEmails[state];
      if (stateData.nonDefaultEmail !== '' && stateData.checked && !isValidEmail(stateData.nonDefaultEmail)) {
        addMessage({ type: 'error', message: `Please enter a valid non-judicial email address for ${state}.` });
        return;
      }
      if (stateData.defaultEmail !== '' && !isValidEmail(stateData.defaultEmail)) {
        addMessage({ type: 'error', message: `Please enter a valid judicial email address for ${state}.` });
        return;
      }
    }

    const invitationData = {
      status: 'PENDING',
      name: companyName,
      states: selectedStates,
      type: 'LAWFIRM',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      role: 'MANAGER',
      stateSpecificEmails: specificEmails,
      website,
      officeAddresses,
      administrators
    }

    const response = await createInvitation({
      variables: {
        input: invitationData,
      },
    });

    if (response.errors) {
      addMessage({ type: 'error', message: 'Please try again later.' });
      return;
    }

    addMessage({ message: "Law firm invite sent!" });

    // Reset every field
    setCompanyName('');
    setOfficeAddresses(['']);
    setWebsite('');
    setAdministrators([{firstName: '', lastName: '', email: ''}]);
    setSelectedStates([]);
    setStateSpecificEmails({});
    handleClose();
  };

  const handleUpdate = async () => {
    if (!formValidation()) return;

    // Test if admin emails are valid
    if (administrators.some((admin) => !isValidEmail(admin.email))) {
      addMessage({ type: 'error', message: 'Please ensure that all administrator email addresses are accurate.' });
      return;
    }

    // Validate specific emails
    for (const state in stateSpecificEmails) {
      const stateData = stateSpecificEmails[state];
      if (stateData.nonDefaultEmail !== '' && stateData.checked && !isValidEmail(stateData.nonDefaultEmail)) {
        addMessage({ type: 'error', message: `Please enter a valid non-judicial email address for ${state}.` });
        return;
      }
      if (stateData.defaultEmail !== '' && !isValidEmail(stateData.defaultEmail)) {
        addMessage({ type: 'error', message: `Please enter a valid judicial email address for ${state}.` });
        return;
      }
    }

    const hasUpdatedStates = [...formData?.states]?.sort().join(',') !== [...selectedStates].sort().join(',');
    const hasUpdatedAdmins = hasDiffAdmins(formData?.administrators, administrators);
    const hasUpdatedAddress = JSON.stringify(formData?.officeAddresses) !== JSON.stringify(officeAddresses);

    const hasUpdatedTeamData = formData?.name !== companyName
      || formData?.website !== website;

    if (hasUpdatedStates || hasUpdatedTeamData || hasUpdatedAdmins || hasUpdatedAddress) {
      // Update team-related data
      const data = {};
      data.officeAddresses = officeAddresses;
      if (formData.name !== companyName)
        data.name = companyName;
      if (formData.website !== website)
        data.website = website;
      if (hasUpdatedStates)
        data.states = selectedStates;
      if (hasUpdatedAdmins) {
        data.administrators = administrators
      }

      try {
        await updateTeamInfo({
          variables: {
            teamID: formData.id,
            data
          }
        });
      } catch (error) {
        console.error('error', error);
        addMessage({ message: 'Error updating team information.', type: 'error' });
      }
    }

    // Update state specific emails
    const stateSpecificUpdate = getStateEmailDifferences(stateSpecificEmails, formData.contacts);
    if (Object.keys(stateSpecificUpdate).length > 0) {
      const specificEmails = Object.keys(stateSpecificUpdate).map((state) => {
        const stateData = stateSpecificUpdate[state];
        return {
          state: state,
          defaultEmail: stateData.defaultEmail,
          nonDefaultEmail: stateData.nonDefaultEmail
        }
      });

      try {
        await updateContactEmails({
          variables: {
            teamID: formData.id,
            emails: specificEmails
          }
        });
      } catch(err) {
        console.error('error', err);
        addMessage({ message: 'Error updating state specific emails.', type: 'error' });
      }
    }

    addMessage({ message: "Law firm updated!" });

    // Reset every field
    setCompanyName('');
    setOfficeAddresses(['']);
    setWebsite('');
    setAdministrators([{firstName: '', lastName: '', email: ''}]);
    setSelectedStates([]);
    setStateSpecificEmails({});
    handleClose();
  };

  const isDisabled = !companyName|| selectedStates.length === 0
    || !officeAddresses.every(address => address.address && address.city && address.county && address.state)
    || !administrators.every((admin) => admin.firstName && admin.lastName && admin.email);

  const getStateType = (state, inverse) => {
    if (inverse) {
      return JUDICIAL.includes(state) ? 'non-judicial' : 'judicial';
    }
    return JUDICIAL.includes(state) ? 'judicial' : 'non-judicial';
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      width="67%"
      height="80%"
    >
      {formData?.id
        ? <h2>Edit law firm</h2>
        : <h2>Invite new law firm</h2>
      }

      <Stack spacing={2}>
        <Stack spacing={2} direction='row'>
          <FormControl sx={{ width: '40%' }}>
            <label>Company Name*</label>
            <TextInput 
              placeholder="Law firm name" 
              name="name"
              required
              value={companyName}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl sx={{ width: '50.5%'}}>
            <label>Website</label>
            <TextInput 
              placeholder="Website URL" 
              name="website"
              required
              value={website}
              onChange={handleInputChange}
            />
          </FormControl>
        </Stack>

        <FormControl>
          <label>Office Address*</label>
          {
            officeAddresses.map((address, index) => {
              return (
                <AddressHolder
                  key={`address-${index}`}>
                  <TextInput 
                    sx={{ width: '25%', marginBottom: '10px' }}
                    placeholder="Street" 
                    name="officeAddresses"
                    required
                    value={address.address}
                    onChange={(e) => {
                      const newAddresses = [...officeAddresses];
                      newAddresses[index] = { ...newAddresses[index], address: e.target.value };
                      setOfficeAddresses(newAddresses);
                    }}
                  />
                  <TextInput 
                    sx={{ width: '15%', marginBottom: '10px' }}
                    placeholder="City" 
                    name="officeAddresses"
                    required
                    value={address.city}
                    onChange={(e) => {
                      const newAddresses = [...officeAddresses];
                      newAddresses[index] = { ...newAddresses[index], city: e.target.value };
                      setOfficeAddresses(newAddresses);
                    }}
                  />
                  <TextInput 
                    sx={{ width: '15%', marginBottom: '10px' }}
                    placeholder="County" 
                    name="officeAddresses"
                    required
                    value={address.county}
                    onChange={(e) => {
                      const newAddresses = [...officeAddresses];
                      newAddresses[index] = { ...newAddresses[index], county: e.target.value };
                      setOfficeAddresses(newAddresses);
                    }}
                  />
                  <TextInput 
                    sx={{ width: '15%', marginBottom: '10px' }}
                    placeholder="State (e.g. CA)" 
                    name="officeAddresses"
                    required
                    value={address.state}
                    onChange={(e) => {
                      const newAddresses = [...officeAddresses];
                      newAddresses[index] = { ...newAddresses[index], state: e.target.value };
                      setOfficeAddresses(newAddresses);
                    }}
                  />
                  <TextInput 
                    sx={{ width: '11%', marginBottom: '10px' }}
                    placeholder="Postal Code" 
                    name="officeAddresses"
                    required
                    value={address.postal}
                    onChange={(e) => {
                      const newAddresses = [...officeAddresses];
                      newAddresses[index] = { ...newAddresses[index], postal: e.target.value };
                      setOfficeAddresses(newAddresses);
                    }}
                  />

                  {index !== officeAddresses.length - 1
                    ? (
                      <RemoveIcon
                        theme={theme}
                        onClick={() => {
                          const newAddresses = [...officeAddresses];
                          newAddresses.splice(index, 1);
                          setOfficeAddresses(newAddresses);
                        }}
                      />
                    )
                    : (
                      <AddIcon
                        onClick={() => {
                          setOfficeAddresses([...officeAddresses, { address: '', city: '', county: '', postal: '', state: '' }]);
                        }}
                      />
                    )
                  }
                </AddressHolder>
              )
            })
          }
        </FormControl>

        <FormControl>
          <label>Administrators*</label>
          {
            administrators.map((admin, index) => {
              return (
                <Stack spacing={2} direction='row' key={`admin-${index}`} sx={{ marginBottom: '5px' }}>
                  <FormControl sx={{ width: '31%' }}>
                    <TextInput 
                      placeholder="First Name" 
                      name="firstName"
                      required
                      value={admin.firstName}
                      onChange={(e) => {
                        const newAdmins = [...administrators];
                        newAdmins[index] = { ...newAdmins[index], firstName: e.target.value };
                        setAdministrators(newAdmins);
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: '31%' }}>
                    <TextInput 
                      placeholder="Last Name" 
                      name="lastName"
                      required
                      value={admin.lastName}
                      onChange={(e) => {
                        const newAdmins = [...administrators];
                        newAdmins[index] = { ...newAdmins[index], lastName: e.target.value };
                        setAdministrators(newAdmins);
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ width: '32%'}}>
                    <TextInput 
                      placeholder="Email" 
                      name="email"
                      required
                      value={admin.email}
                      onChange={(e) => {
                        const newAdmins = [...administrators];
                        newAdmins[index] = { ...newAdmins[index], email: e.target.value };
                        setAdministrators(newAdmins);
                      }}
                    />
                  </FormControl>
                  {index !== administrators.length - 1
                    ? (<RemoveIcon
                      theme={theme}
                      onClick={() => {
                        const newAdmins = [...administrators];
                        newAdmins.splice(index, 1);
                        setAdministrators(newAdmins);
                      }}
                    />
                    )
                    : (<AddIcon
                      onClick={() => {
                        setAdministrators([...administrators, { firstName: '', lastName: '', email: '' }]);
                      }}
                      theme={theme}
                    />)
                  }
                  
                </Stack>
              )
            })
          }
        </FormControl>

        <FormControl>
          <label>States*</label>
          <Select
            data-testid="id-state-selection"
            sx={{ width: 500 }}
            multiple
            required
            value={selectedStates}
            onChange={handleStateChange}>
            {dropdownValues.map((dv) =>
              <MenuItem key={dv.value} value={dv.value}>{dv.text}</MenuItem>
            )}
          </Select>
        </FormControl>
        
        {selectedStates.length > 0 && (
          <Stack spacing={2} direction='column' sx={{overflowY: 'scroll', height: '100%'}}>
            <Typography><strong>Jurisdiction-specific referral emails:</strong></Typography>
            <Typography display="block">
            If your law firm uses a separate referral email address for judicial/non-judicial processes in a given state, you have the option of defining one using the checkboxes below.
            </Typography>
            <Typography>
              All notifications will be sent to the primary contact email address if no jurisdiction-specific referral email addresses are defined.
            </Typography>
            {selectedStates.map((state) => {              
              return (<Stack spacing={2} direction='column' key={state}>
                <Stack spacing={2} direction='row'>
                  <FormControl>
                    <label>Send {state} referrals to:</label>
                    <TextInput
                      value={stateSpecificEmails[state]?.defaultEmail}
                      key={state} 
                      placeholder={`Email for ${state}`} 
                      name="name"
                      onChange={(e) => onChangeSpecificStateEmails(state, 'default', e.target.value)}
                      sx={{ width: 300 }}
                    />
                  </FormControl>
                  <FormControlLabel sx={{ width: 350 }} control={<Checkbox onChange={() => handleStateSpecificEmails(state)} checked={stateSpecificEmails[state]?.checked}/>} label={`Set ${getStateType(state, true)} referral emails for ${state}`} />
                  {stateSpecificEmails[state]?.checked && (
                    <FormControl>
                      <label>Send {getStateType(state, true)} {state} referrals to:</label>
                      <TextInput 
                        value={stateSpecificEmails[state]?.nonDefaultEmail}
                        key={state} 
                        placeholder="Email" 
                        name="name"
                        onChange={(e) => onChangeSpecificStateEmails(state, 'nondefault', e.target.value)}
                        sx={{ width: 300 }}
                      />
                    </FormControl>
                  )}
                </Stack>
              </Stack>
              )})}
          </Stack>
        )}

        {
          formData?.id
            ? <Button
              onClick={handleUpdate}
              disabled={isDisabled || loading}
              sx={{ width: 300 }}
              loading={loading}
            >
            Save
            </Button>
            : <Button
              onClick={handleSubmit}
              disabled={isDisabled || loading}
              sx={{ width: 300 }}
              loading={loading}
            >
            Send Invite
            </Button>
        }
      </Stack>
    </Modal>
  );
};

export default LawfirmInviteModal;