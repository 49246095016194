import React, { useContext, useState } from 'react';
import Drawer from '../../../styled/Drawer/Drawer';
import { MessageContext } from '../../../context/MessageContext';
import Typography from '../../../styled/Typography/Typography';
import styled from '@emotion/styled';
import { Box, FormControl, TextField } from '@mui/material';
import { rgba } from 'emotion-rgba';
import { useTheme } from "@emotion/react";
import Button from '../../../styled/Button/Button';
import { UPDATE_ACTIVITY } from '../../../apollo/mutations/updateActivity';
import { useMutation } from '@apollo/client';

const Header = styled(Box)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => rgba(theme.themeColor.bodyMain, 0.1)};
  height: 60px;
  color: ${({theme}) => theme.themeColor.bodySecondary};
  margin: 0 -25px;
  padding: 25px;
`

export default function EditActivity({ open, onClose, activity }) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);

  const [formData, setFormData] = useState({ name: activity?.name || "", description: activity?.description || "" });
  const [updateActivity] = useMutation(UPDATE_ACTIVITY, {
    awaitRefetchQueries: true,
    refetchQueries: ['FindAsset'],
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const resp = await updateActivity({
        variables: {
          activity: {
            id: activity.id,
            name: formData.name,
            description: formData.description,
          }
        },
      });

      if ('errors' in resp) {
        addMessage({ message: "Something went wrong while updating the activity.", type: "error" });
      } else {
        addMessage({ message: 'Activity updated!' });
        onClose();
      }
    } catch(e) {
      addMessage({ message: "Something went wrong while updating the activity.", type: "error" })
    }
  }

  return (
    <Drawer open={open} onClose={onClose}
      height="50vh"
      styling={{ margin: "25vh 20px !important", overflowY: "auto" }}>
      <Header theme={theme}>
        {activity?.name
          ? <Typography variant="h5">{activity.name}</Typography>
          : <Typography variant="h5">
            General Activity
          </Typography>
        }
      </Header>
      <FormControl sx={{mt:5}}>
        <TextField
          name="name"
          variant='outlined'
          label="Activity Name"
          onChange={handleChange}
          value={formData.name}
          id="name"
          data-testid="update-activity-name"
        />
        <TextField
          name="description"
          variant='outlined'
          label="Activity Description"
          onChange={handleChange}
          value={formData.description}
          id="description"
          sx={{ mt: '30px' }}
          multiline={5}
          data-testid="update-activity-description"
        />
      </FormControl>
      
      <Button
        sx={{mt: 8}}
        onClick={handleSubmit}
        data-testid="update-activity-button"
      >Confirm</Button>
    </Drawer>
  )
}