import { gql } from "@apollo/client";

export const CREATE_TASK_FLOW_TEMPLATE_MUTATION = gql`
  mutation CreateTaskFlowTemplateMutation($input: createTaskFlowTemplateInput) {
    createTaskFlowTemplate(input: $input) {
      id
      name
      description
      subtasks
      deleted
      createOnMilestone
      createOnMilestoneID
      states
      version
    }
  }
`;
