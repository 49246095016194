export const FIRST_LEGAL_GENERIC_JUDICIAL = [
  { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
  { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
  { "name": "File Complaint", "order": 3, "budgetDays": 5 },
];

export const FIRST_LEGAL_GENERIC_NONJUDICIAL = [
  { "name": "NOD Filed", "order": 1, "budgetDays": 5 },
  { "name": "NOD Recorded", "order": 2, "budgetDays": 20 },
];

export const FIRST_LEGAL_NONJUDICIAL = [
  { "state": "AL", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 10 }, { "name": "First Publication", "order": 2, "budgetDays": 0 }] },
  { "state": "AK", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "AR", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "AZ", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "CA", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "CO", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "Public Trustee Received", "order": 1, "budgetDays": 10 }] },
  { "state": "DC", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "GA", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "ID", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }, { "name": "NOD Recorded", "order": 2, "budgetDays": 5 }] },
  { "state": "MA", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "MD", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "Dockets Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "MI", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 15 }] },
  { "state": "MN", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 25 }] },
  { "state": "MO", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "MS", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "MT", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOS Filed", "order": 1, "budgetDays": 10 }] },  
  { "state": "NC", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "Notice of Hearing Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "NE", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "NH", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "NV", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "OR", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "RI", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "TN", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "TX", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Posted", "order": 1, "budgetDays": 21 }] },
  { "state": "UT", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "VA", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "WA", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "NOD Filed", "order": 1, "budgetDays": 5 }] },
  { "state": "WV", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
  { "state": "WY", "county": null, "processType": "nonJudicial", "subtaskData": [{ "name": "First Publication", "order": 1, "budgetDays": 10 }] },
];

export const FIRST_LEGAL_JUDICIAL = [
  { "state": "CT", "county": null, "processType": "judicial", "subtaskData": [{ "name": "Service sent (1st action)", "order": 1, "budgetDays": 10 }] },
  { "state": "DE", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 10 },
    ]
  },
  { "state": "FL", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 10 },
    ]
  },
  { "state": "HI", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 25 },
    ]
  },
  { "state": "IA", "county": null, "processType": "judicial", "subtaskData": [{ "name": "Petition Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "HI", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 10 },
    ]
  },
  { "state": "IN", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "KS", "county": null, "processType": "judicial", "subtaskData": [{ "name": "Petition Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "KY", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "LA", "county": null, "processType": "judicial", "subtaskData": [{ "name": "Petition Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "ME", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "ND", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "NJ", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 20 },
    ]
  },
  { "state": "NM", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "NY", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 10 },
    ]
  },
  { "state": "OH", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "OK", "county": null, "processType": "judicial", "subtaskData": [{ "name": "Petition Filed", "order": 1, "budgetDays": 10 }] },
  { "state": "PA", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "PR", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "SC", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "SD", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "VT", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },
  { "state": "WI", "county": null, "processType": "judicial",
    "subtaskData": [
      { "name": "Draft Complaint", "order": 1, "budgetDays": 5 },
      { "name": "Upload Complaint", "order": 2, "budgetDays": 0 },
      { "name": "File Complaint", "order": 3, "budgetDays": 5 },
    ]
  },

]