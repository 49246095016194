import React, { useState, useContext } from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import SplitButton from "../../styled/SplitButton/SplitButton";
import Button from "../../styled/Button/Button";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import { REMOVE_TEAM } from "../../apollo/mutations/removeTeam";
import { useMutation } from "@apollo/client";
import { MessageContext } from "../../context/MessageContext";
import LawfirmInviteModal from "./LawfirmInviteModal";

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
`

export default function ActionColumn({ value, ...rest }) {  
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditLawfirm, setOpenEditLawfirm] = useState(false);

  const { addMessage } = useContext(MessageContext);
  const { id: teamID } = rest.data;

  const [removeTeam, { loading }] = useMutation(REMOVE_TEAM, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });

  const deleteTeam = async () => {
    try {
      const deleteResponse = await removeTeam({
        variables: {
          teamID
        }
      })
      if (deleteResponse.errors){
        throw deleteResponse.errors[0].message;
      } else {
        addMessage({ message: "Team removed!" });
        rest.api.sizeColumnsToFit();
      }
    } catch(error) {
      addMessage({ message: error, type: "error" })
    }
  }

  if (value === 'fr-inviteStatus') {
    return (
      <>
        <Button onClick={() => setOpenInviteModal(true)} disabled={loading} sx={{width: '150px', height: '85%'}}>Invite</Button>
        <LawfirmInviteModal open={openInviteModal} handleClose={() => setOpenInviteModal(false)} />
      </>
    );
  }

  const getOptions = () => {
    return [
      { label: 'Edit', onClick: () => setOpenEditLawfirm(true), color: 'black'},
      { label: 'Delete', onClick: () => setOpenConfirmDelete(true), color: 'red'},
    ]
  }

  const labelGenerator = (value) => {
    if (value === 'ACCEPTED') return 'Accepted';
    else if (value === 'PENDING') return 'Pending';
    else return 'Rejected';
  }
  const colorGenerator = (value) => {
    if (value === 'ACCEPTED') return 'black';
    else if (value === 'PENDING') return 'ltGray';
    else return 'red';
  }

  const clickConfirmDelete = () => {
    setOpenConfirmDelete(false);
    deleteTeam();
  }

  return (
    <Box>
      <SplitButton
        label={labelGenerator(value)}
        color={colorGenerator(value)}
        items={getOptions(value)}
      />
      <LawfirmInviteModal formData={rest.data} open={openEditLawfirm} handleClose={() => setOpenEditLawfirm(false)} />

      <Modal open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)}>
        <Typography variant="h5" mb={2}>Are you sure you want to delete this team?</Typography>
        <Typography>There are {rest.data.assets} assets referred to this law firm.</Typography>
        <Typography>Deleting this team will remove all of its associated referrals and the assets must be re-referred.</Typography>

        <ButtonBox>
          <Button onClick={clickConfirmDelete} sx={{ width: "33%", marginRight: "30px" }}>
            Confirm
          </Button>

          <Button
            variant="secondary"
            onClick={() => setOpenConfirmDelete(false)}
            sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
          >
          Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </Box>
  );
}
