import { gql } from '@apollo/client';

export const UPDATE_TEAM_INFO = gql`
  mutation updateTeam($teamID: ID!, $data: JSONObject!) {
    updateTeam(teamID: $teamID, data: $data) {
      id
      name
    }
  }
`;
