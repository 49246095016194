import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Tooltip } from '@mui/material';

const Dot = styled('div')`
  border: ${({theme, color}) => {
  if (!color) return `1px dashed ${theme.themeColor.bodySecondary}`;
  return 'none';
}};
  border-radius: 8px;
  width: 8px;
  height: 8px;

  background: ${(props) => {
  switch (props.color) {
  case 'green':
    return props.theme.themeColor.brandPrimaryGreen;
  case 'red':
    return props.theme.themeColor.brandPrimaryRed;
  default:
    return 'transparent';
  }
}};
  
`

const ColoredDot = ({ color, label, ...props }) => {
  const theme = useTheme();

  return (
    <Tooltip
      title={label}
      placement="top"
      data-testid="initials-tooltip"
      {...props}
    >
      <Dot theme={theme} color={color} alt="teste" />
    </Tooltip>
  )
}

export default ColoredDot;