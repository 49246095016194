import { Box } from "@mui/material";
import React from "react";
import Button from "../../styled/Button/Button";

const TemplateSettings = ({ data }) => {
  if (!data || !data?.showButtons) return null;

  const flow = { ...data, subtasks: data.allSubtasks }
  return (
    <>
      <Box sx={{ width: '100px' }}>
        <Button sx={{ mr: 1 }} variant="secondary" onClick={() => data.onSelectFlow(flow)}>Select</Button>
      </Box>
    </>
  )
}

export default TemplateSettings;