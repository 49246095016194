import React, { useState, useContext } from 'react';
import differenceInDays from "date-fns/differenceInDays";
import Modal from '../../styled/Modal/Modal';
import Typography from '../../styled/Typography/Typography';
import { TextInput } from '../../styled/TextInput/TextInput';
import Button from '../../styled/Button/Button';
import { useTheme } from '@emotion/react';
import { useMutation } from '@apollo/client';
import { REMOVE_ASSET } from '../../apollo/mutations/removeAsset';
import { MessageContext } from '../../context/MessageContext';
import styled from '@emotion/styled';

const Input = styled(TextInput)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export default function SoftDeleteArchivedLoan({ open, onClose, asset }) {
  const theme = useTheme();
  const { addMessage } = useContext(MessageContext);
  const [deleteText, setDeleteText] = useState('');
  const daysRemaining = differenceInDays(new Date(), new Date(+asset.archiveDate));

  const [removeAsset] = useMutation(REMOVE_ASSET, {
    awaitRefetchQueries: true,
    refetchQueries: 'active'
  });

  const handleSubmit = async () => {
    try {
      await removeAsset({ variables: { assetID: asset.id } });
      addMessage({ text: 'Loan deleted successfully.', variant: 'success' });
    } catch(err) {
      addMessage({ text: 'An error occurred while deleting the loan.', variant: 'error' });
    }
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Typography variant="h5">Are you sure you want to delete this archived loan?</Typography>
      <Typography
        sx={{ color: theme.themeColor.brandPrimaryRed}}
        mt={1} mb={1}
      >This action cannot be undone.</Typography>

      <Typography mb={2}>This loan has {15 - daysRemaining} days remaining in the free archived period of 15 days.</Typography>

      <Typography>Deleting this loan will also remove all of its associated data.</Typography>
      <Typography mb={2}>If you are sure, type the word &quot;<strong>DELETE</strong>&quot; in the box below and click the Delete button.</Typography>
      
      <Input
        value={deleteText}
        onChange={e => setDeleteText(e.target.value)}
        placeholder="DELETE"
        data-testid="delete-archived-loan-input"
      />

      <Button
        disabled={deleteText !== 'DELETE'}
        onClick={handleSubmit}
        data-testid="delete-archived-loan-button"
      >Delete</Button>
    </Modal>
  )
}