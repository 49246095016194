import React, { Fragment, useContext, useEffect, useState } from "react";
import "../styles/app.scss";
import { Switch, Route } from "react-router-dom";
import appRoutes from "../appRoutes";
import { PrivateRoute } from "../components/PrivateRoute/PrivateRoute";
import Header from "./Header/Header";
import AssetDetails from "../pages/AssetDetails";
import AuthPage from "../pages/AuthPage";
import Footer from "./Footer/Footer";
import ResetPassword from "../pages/ResetPassword";
import AssetImport from "../pages/AssetImport";
import Lawfirms from "../pages/Lawfirms";
import Teams from "../pages/Teams";
import SwitchUserType from "./SwitchUserType/SwitchUserType";
import Users from "../pages/Users";
import Messages from "./Messages/Messages";
import { MessageContext } from "../context/MessageContext";
import TermsAndPrivacy from "../pages/TermsAndPrivacy";
import AccountSettings from "../pages/AccountSettings";
// import AddApi from "../pages/AddApi";
import { UserContext } from "../context/UserContext";
import Documents from "../pages/Documents";
import NotificationsCenter from "../pages/NotificationsCenter";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { rgba } from "emotion-rgba";
import { lightColors, darkColors } from "../theme";
import CssBaseline from "@mui/material/CssBaseline";
import Payment from "../pages/Payment";
import Billing from "../pages/Billing";
import InvoicePayment from "../pages/InvoicePayment";
import ApplyCoupon from "./ApplyCoupon/ApplyCoupon";
import * as Sentry from "@sentry/react";
import UserManual from "./UserManual/UserManual";
import StaffTasks from "../pages/StaffTasks";
import InFlightLoans from "./InFlightLoans/InFlightLoans";

function App({ client }) {
  const [colors, setColors] = useState(lightColors);

  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const userTheme = window.localStorage.getItem('theme');

  useEffect(() => {
    if (userTheme && userTheme === "dark") {
      setColors(darkColors);
    } else setColors(lightColors)
  }, [userTheme]);

  const onChangeTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      window.localStorage.setItem("theme", "dark");
      setColors(darkColors);
    } else {
      window.localStorage.setItem("theme", "light");
      setColors(lightColors);
    } 
  };

  const theme = createTheme({
    typography: {
      fontFamily: ["IBM Plex Sans"].join(","),
    },
    themeFonts: {
      main: "IBM Plex Sans",
      complimentary: "IBM Plex Mono",
    },
    themeSizing: {
      borderRadiusLarge: "8px",
      borderRadiusSmall: "4px",
    },
    palette: {
      background: {
        default: colors.backgroundBody,
      },
      text: {
        primary: colors.bodySecondary,
      },
    },
    themeColor: {
      ...colors,
      complexCheckbox: {
        grey: "#f3f4f4",
        lightBlue: "#f3f7fe",
      },
      userIcon: {
        background: rgba(colors.brandPrimaryBlue, 0.2),
        text: colors.brandPrimaryBlue,
      },
    },
    components: {
      // name of the component
      MuiInput: {
        styleOverrides: {
          root: {
            backgroundColor: "#fafafa",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            fontSize: "1rem",
            textTransform: "none",
            "&:disabled": {
              backgroundColor: rgba(colors.bodyMain, 0.2),
              color: rgba(colors.bodyMain, 0.4),
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          loadingIndicator: {
            color: rgba(colors.bodyMain, 0.4),
          },
        },
      },
      MuiBadge: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: colors.brandPrimaryBlue,
          },
          colorSecondary: {
            backgroundColor: rgba(colors.brandPrimaryBlue, 0.75)
          }
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: colors.lightGray,
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            backgroundColor: colors.veryLightGray,
            padding: "8px 16px",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&.Mui-expanded": {
              margin: 0,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: colors.bodyTetriary,
            "&.Mui-checked": {
              color: colors.brandPrimaryBlue,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: colors.bodyTetriary,
            "&.Mui-checked": {
              color: colors.brandPrimaryBlue,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: "none",
            lineHeight: "20px",
            color: colors.bodySecondary,
            maxWidth: "108px",
            "&.Mui-selected": {
              color: colors.bodyMain,
              background: colors.sectionFill,
            },
            "&:hover": {
              borderBottom: `2px solid ${colors.brandPrimaryGray}`,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            background: colors.backgroundBody,
          },
          indicator: {
            backgroundColor: colors.brandPrimaryBlue,
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            backgroundColor: `${rgba(colors.brandPrimaryBlue, 0.1)}`,
            color: colors.brandPrimaryBlue,
          },
          outlined: {
            backgroundColor: "transparent",
            borderColor: colors.brandPrimaryBlue,
          },
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: ".85rem",
            fontFamily: "IBM Plex Sans",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            "& label": {
              color: colors.bodySecondary,
            },
            "& label.Mui-focused": {
              color: colors.brandPrimaryBlue,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.bodySecondary,
              },
              "&:hover fieldset": {
                borderColor: colors.brandPrimaryBlue,
                borderWidth: "0.15rem",
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.brandPrimaryBlue,
              },
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
              },
            },
            "& .Mui-disabled": {
              color: colors.bodyTetriary,
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label": {
              color: colors.bodySecondary,
            },
            "& label.Mui-focused": {
              color: colors.brandPrimaryBlue,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.bodySecondary,
              },
              "&:hover fieldset": {
                borderColor: colors.brandPrimaryBlue,
                borderWidth: "0.15rem",
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.brandPrimaryBlue,
              },
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
              },
            },
            "& .Mui-disabled": {
              color: colors.bodyTetriary,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: colors.bodyMain,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: rgba(colors.bodyMain, 0.2),
            },
          },
          icon: {
            color: colors.bodyMain,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: colors.bodySecondary,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: colors.backgroundBody,
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              background: colors.sectionStroke,
            },
          }
        }
      }
    },
  });


  const { messages, removeMessage } = useContext(MessageContext);
  const user = useContext(UserContext).user;

  if (user) {
    Sentry.setUser({ email: user.email });
  }

  // if user hasn't paid, then open Stripe page for payment
  if (user && user.stripeInvoiceURL) {
    return <InvoicePayment />
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fragment>
        <Switch>
          <Route path="/auth">
            <AuthPage client={client} />
          </Route>
          <Route path="/reset-password/:token" component={ResetPassword} />
          <PrivateRoute
            path="/payment"
            user={user}
            permissions={["PORTFOLIO_MANAGER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} />
              <Payment />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/applycoupon"
            user={user}
            permissions={["PORTFOLIO_MANAGER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} />
              <ApplyCoupon />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/billing"
            user={user}
            permissions={["PORTFOLIO_MANAGER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} />
              <Billing />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <SwitchUserType client={client} />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/import-assets"
            user={user}
            permissions={["PORTFOLIO_MANAGER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <AssetImport client={client} />
              <Footer />
            </div>
          </PrivateRoute>
          {/* <PrivateRoute
            path="/add-api"
            user={user}
            permissions={["PORTFOLIO_MANAGER", "PORTFOLIO_MEMBER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <AddApi />
              <Footer />
            </div>
          </PrivateRoute> */}
          <PrivateRoute
            path="/documents"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <Documents />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/lawfirms"
            user={user}
            permissions={["PORTFOLIO_MANAGER", "PORTFOLIO_MEMBER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <Lawfirms />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/teams"
            user={user}
            permissions={["PORTFOLIO_MANAGER", "PORTFOLIO_MEMBER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <Teams />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            path="/users"
            user={user}
            permissions={["PORTFOLIO_MANAGER", "TEAM_MANAGER"]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <Users />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/assets/:assetID"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
            <AssetDetails client={client} />
            <Footer />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/terms-and-conditions"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={[]} handleChangeTheme={onChangeTheme} />
              <TermsAndPrivacy />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/account-settings"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <AccountSettings />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/notifications-center"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <NotificationsCenter />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/user-manual"
            user={user}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <div className="heightWithHeader">
              <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
              <UserManual />
              <Footer />
            </div>
          </PrivateRoute>
          <PrivateRoute
            user={user}
            path={`/jd/task-list`}
            permissions={[
              "PORTFOLIO_MANAGER",
              "PORTFOLIO_MEMBER",
              "TEAM_MANAGER",
              "TEAM_MEMBER",
            ]}
          >
            <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
            <StaffTasks />
            <Footer />
          </PrivateRoute>
          <PrivateRoute
            user={user}
            path={`/inflight-loans`}
            permissions={[
              "PORTFOLIO_MANAGER"
            ]}
          >
            <Header routes={appRoutes} handleChangeTheme={onChangeTheme} />
            <InFlightLoans />
            <Footer />
          </PrivateRoute>
        </Switch>
        <Messages messages={messages} removeMessage={removeMessage} />
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
