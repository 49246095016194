import React, { useState, useRef, useContext } from "react";
import { useMutation } from "@apollo/client";
import { EDIT_TEAM_STATES } from "../../apollo/mutations/editTeamStates";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { ALL_STATES } from "../../utilities/genericSteps";
import { MessageContext } from "../../context/MessageContext";
import { TextInput } from "../../styled/TextInput/TextInput";
import { useTheme } from "@emotion/react";
import styled from '@emotion/styled';

const StateContainer = styled.div`
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const AutocompleteWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.themeColor.backgroundBody};
  z-index: 9999;
  margin-top: ${({ row, value }) => {
  if (row === 0) {
    if (value.length >= 4) return '10vh';
    else if (value.length === 3) return '8vh';
    else if (value.length === 2) return '5vh';
    else if (value.length === 1) return '2.5vh';
    else if (value.length === 0) return '1vh';
    return '10vh'
  }
  return '1vh';
}};
`;

const LawfirmStates = ({ value, ...rest }) => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const { addMessage } = useContext(MessageContext);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectValue, setSelectValue] = useState(value || []);

  const [editTeamStates] = useMutation(EDIT_TEAM_STATES);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  }
  const handleStateUpdate = (event, newValue) => {
    setSelectValue(newValue);
  }

  const onClickAway = async () => {
    setOpenDropdown(false);

    const areStatesEqual = (given, selected) => {
      if (given.length !== selected.length) return false;
      
      const givenSet = new Set(given);
      const selectedSet = new Set(selected);
      if (givenSet.size !== selectedSet.size) return false;

      for (let value of givenSet) {
        if (!selectedSet.has(value)) {
          return false;
        }
      }
      return true;
    }

    if (!selectValue.length) {
      addMessage({ message: 'Please select at least one state', type: 'error' });
      setSelectValue(value);
      return;
    }

    if (!areStatesEqual(value, selectValue)) {
      try {
        await editTeamStates({
          variables: {
            teamID: rest.data.id,
            states: selectValue
          }
        });

        addMessage({ message: 'States updated successfully', type: 'success' });
      } catch (error) {
        console.error('error', error);
        addMessage({ message: 'Error updating states', type: 'error' });
      }
    }
  }

  if (value) {
    return openDropdown
      ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <AutocompleteWrapper theme={theme} value={selectValue} row={rest.node.rowIndex}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              popupIcon={""}
              size="small"
              options={ALL_STATES}
              disableClearable
              disablePortal
              onChange={handleStateUpdate}
              value={selectValue}
              renderInput={(params) => <TextInput {...params}
                size="small"
                InputProps={{ ...params.InputProps, }}
                sx={{
                  // control height
                  '& .MuiInputBase-root': {
                    height: '15vh', 
                    overflow: 'auto'
                  },
                  // hide border (looks weird with scrolling)
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'transparent',
                    },
                  },
                  '& .MuiOutlinedInput-input:focus': {
                    outline: 'none',
                  },
                }}
              />}
            />
          </AutocompleteWrapper>
        </ClickAwayListener>
      )
      : (
        <Box sx={{display: 'flex', alignItems: 'center', height: '100%', width: "100%"}}>
          <StateContainer data-testid="state-container" ref={anchorRef} onClick={toggleDropdown}>{value.join(', ') || ''}</StateContainer>
        </Box>
      );
  }

  return '';
};

export default LawfirmStates;