import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Container } from '@mui/material';
import { gql, useQuery } from "@apollo/client";
import CellWrapper from '../GridInputCell/CellWrapper';
import ActionColumn from './ActionColumn';
import { UserContext } from '../../context/UserContext';
import { GET_TEAMS_BY_USER_PORTFOLIO_ID } from '../../apollo/queries/findTeamsByUserPortfolioId';
import StandardGrid from '../Grid/Grid';
import TableSkeleton from '../Skeleton/TableSkeleton';
import TableDateTime from '../TableDateTime/TableDateTime';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Typography from '../../styled/Typography/Typography';
import Modal from '../../styled/Modal/Modal';
import Button from '../../styled/Button/Button';
import { capitalValueFormatter } from '../../utilities';
import MembersList from '../MembersList/MembersList';
import { ALL_STATES } from '../../utilities/genericSteps';
import LawfirmStates from './LawfirmStates';

export const CREATE_INVITATION = gql`
  mutation CREATE_INVITATION($input: createInvitationInput) {
    createInvitation(input: $input) {
      id
      email
    }
  }
`
const myColsDef = [
  {
    headerName: "Company Name",
    field: "name",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'Company Name'
    }
  },
  {
    headerName: "E-mail",
    field: "email",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'E-mail'
    }
  },
  {
    headerName: "First Name",
    field: "firstName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'First Name'
    }
  },
  {
    headerName: "Last Name",
    field: "lastName",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      label: 'Last Name'
    },
  },
  {
    headerName: "States",
    field: "states",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellRendererParams: {
      dropdownValues: ALL_STATES.map((state) => ({ text: state, value: state })),
      multiple: true,
    },
    cellClass: "ag-show-dropdown-modal",
  },
  {
    headerName: "Assets",
    field: "assets",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Members",
    field: "members",
    sortable: true,
    filter: true,
    cellRenderer: "CellWrapper",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Created",
    field: "dateCreated",
    sortable: true,
    filter: true,
    cellRenderer: "TableDateTime",
    cellStyle: { fontFamily: 'IBM Plex Mono' },
  },
  {
    headerName: "Invite Status",
    field: "inviteStatus",
    sortable: true,
    filter: true,
    filterParams: {
      valueFormatter: capitalValueFormatter
    },
    cellRenderer: "ActionColumn",
    cellClass: "ag-show-dropdown-modal",
    suppressColumnsToolPanel: true
  },
];

const frameworkComponents = {
  ActionColumn,
  CellWrapper,
  TableDateTime,
  LawfirmStates
}

const TableContainer = styled(Box)`
  margin: 0 auto;
  height: 98%;
  width: 98%;
  border: ${({ theme }) => `1px solid ${theme.themeColor.sectionStroke}`};
  border-radius: ${({ theme }) => theme.themeSizing.borderRadiusLarge};
`

const ls = window.localStorage;

const PINNED_ROW_DATA = [{
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  states: '',
  inviteStatus: 'fr-inviteStatus',
  assets: '',
  dateCreated: '',
  members: '',
}]

export default function LawfirmsList() {
  const { user } = useContext(UserContext);
  const gridApiRef = useRef(null);
  const theme = useTheme();

  const [columnDefs, setColumnDefs] = useState(myColsDef);
  const [teams, setTeams] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTeamID, setSelectedTeamID] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  function onGridReady(params) {
    gridApiRef.current = params.api;
  }

  function onGridSizeChanged() {
    gridApiRef.current.sizeColumnsToFit();
  }

  const { loading, data } = useQuery(GET_TEAMS_BY_USER_PORTFOLIO_ID);

  useEffect(() => {
    if (data) {
      const teams = [];

      [...data.findTeamsByUserPortfolioId].forEach((team) => {
        if (team.type !== 'LAWFIRM') return;
        const teamCopy = { ...team };

        const teamPortfolioUsers = teamCopy.portfolio?.users.length
          ? teamCopy.portfolio.users.map((user) => user.id)
          : [];

        const members = team.memberships.filter((member) => {
          return member.user.id != user?.userID && !member.deleted && !teamPortfolioUsers.includes(member.user.id);
        })
        teamCopy.memberships = members;
        teams.push(teamCopy);
      })
      const transformedTeams = transformTeamData(teams);
      setTeams(transformedTeams);
    }
    if (gridApiRef?.current)
      setTimeout(() => gridApiRef.current.sizeColumnsToFit(), 0);
  }, [data, selectedTeamMembers]);

  useEffect(() => {
    const visibleColumns = ls.getItem('lawfirmsList') ? JSON.parse(ls.getItem('lawfirmsList')) : [];
    const newCols = [...columnDefs].map((col) => {
      const found = visibleColumns.find((vc) => vc.colId === col.field);
      if (found && found.colId != 'inviteStatus') {
        col.hide = found.hide;
      } 
      return col
    });
    if (newCols) setColumnDefs(newCols);
  }, []);

  const gridOptions = {
    suppressPropertyNamesCheck: true,
    pagination: true,
    rowSelection: 'single',
    columnDefs,
    pinnedTopRowData: PINNED_ROW_DATA
  };

  if (loading) return (
    <Container sx={{ height: '100%' }}>
      <TableSkeleton columnDefs={myColsDef} />
    </Container>
  );

  function transformTeamData(teams) {
    const transformedTeams = teams.map((item) => {
      const firstInvitation = [...item.invitations].sort((a, b) => a.id - b.id)[0];
      const stateContacts = {};

      item?.contacts?.length && item.contacts.forEach((item) => {
        stateContacts[item.state] = {
          defaultEmail: item.defaultEmail,
          nonDefaultEmail: item.nonDefaultEmail,
          checked: item.nonDefaultEmail && item.nonDefaultEmail !== ''
        }
      })

      return {
        id: item.id,
        name: item.name,
        assets: item.assets.length || 0,
        dateCreated: new Date(+item.createdAt),
        states: item.states,
        inviteStatus: firstInvitation ? firstInvitation.status : "",
        email: firstInvitation ? firstInvitation.email : "",
        members: item.memberships.length || 0,
        memberships: item.memberships,
        type: item.type,
        firstName: firstInvitation ? firstInvitation.firstName : "",
        lastName: firstInvitation ? firstInvitation.lastName : "",
        contacts: stateContacts,
        contactsData: item.contacts,
        invitationID: firstInvitation ? firstInvitation.id : null,
        website: item.website,
        administrators: item.administrators,
        officeAddresses: item.officeAddresses,
      }
    });

    return transformedTeams;
  }

  function onCellClicked(e) {
    if (
      e.column.colId === 'inviteStatus' ||
      e.column.colId === 'states'
    ) return;
    // ignore clicks on pinned row
    if (!e.data.id) return;

    const team = gridApiRef.current.getSelectedRows()[0];
    const memberships = team.memberships.map((member) => ({
      ...member, teamType: team.type
    }))

    if (team) {
      setSelectedTeamID(team.id)
      setSelectedTeam(team)
      setSelectedTeamMembers(memberships)
      setShowModal(true)
    }
  }

  return (
    <TableContainer sx={{ height: '100%', border: `1px solid ${theme.themeColor.sectionStroke}` }}>
      <StandardGrid
        tableName="lawfirmsList"
        onGridReady={onGridReady}
        rowData={teams}
        gridOptions={gridOptions}
        onGridSizeChanged={onGridSizeChanged}
        components={frameworkComponents}
        onCellClicked={onCellClicked}
      />

      <Modal open={showModal} width="90%" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4" component="h2">{teams?.find(t => t.id == selectedTeamID)?.name}</Typography>
        <MembersList teamID={selectedTeamID} selectedTeam={selectedTeam} selectedTeamMembers={selectedTeamMembers} setSelectedTeamMembers={setSelectedTeamMembers} />
        <Button
          onClick={() => {
            setShowModal(false);
          }}
          sx={{
            width: 'auto',
            border: 'none',
            boxShadow: 'none',
            marginLeft: '15px',
          }}
        >
          Close
        </Button>
      </Modal>
    </TableContainer>
  )
}