import React, { useState, useEffect, useContext } from "react";
import { Box, Autocomplete, Select, MenuItem } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_TEAMS_BY_USER_PORTFOLIO_ID } from "../../apollo/queries/findTeamsByUserPortfolioId";
import { CREATE_PROCESS } from "../../apollo/mutations/createProcess";
import { START_REFERRAL_PROCESS_MUTATION } from "../../apollo/mutations/startReferralProcessMutation";
import { REFER_PREVIOUS_PROCESS } from "../../apollo/mutations/referPreviousProcess";
import { GET_PROCESS_TYPES } from "../../apollo/queries/getProcessTypes";
import { END_CURRENT_PROCESS } from "../../apollo/mutations/endCurrentProcess";
import { UserContext } from "../../context/UserContext";
import { MessageContext } from "../../context/MessageContext";
import Modal from "../../styled/Modal/Modal";
import { TextInput } from "../../styled/TextInput/TextInput";
import Typography from "../../styled/Typography/Typography";
import Button from "../../styled/Button/Button";
import styled from "@emotion/styled";
import Checkbox from "../../styled/Checkbox/Checkbox";
import { formatProcessType } from "../../utilities";
import { GENERIC_JUDICIAL, GENERIC_NONJUDICIAL, isJudicial } from "../../utilities/genericSteps";
const fcSteps = require('../../utilities/foreclosureSteps.json');
const bkSteps = require('../../utilities/bkSteps.json');

const StyledInput = styled(TextInput)`
  margin-top: 25px;
`
const getBillingStatus = (processType) => {
  if (processType === "stateDefault" || processType === "judicial" || processType === "nonJudicial")
    return "IN_FORECLOSURE";
  else if (processType === "chapter7" || processType === "chapter11" || processType === "chapter13")
    return "IN_BANKRUPTCY";
}
const getSteps = (processType, asset) => {
  if (processType === "stateDefault" || processType === "foreclosure") {
    return [
      ...fcSteps.transformedRow.filter((step) => {
        if (asset.borough) {
          const fStep = step.state === asset.state && step.borough && step.borough.toUpperCase() === asset.borough.toUpperCase();
          if (!fStep) return step.state === asset.state && step.borough === null;
          return fStep;
        } else {
          return step.state === asset.state && step.borough === null;
        }
      }),
    ][0].steps;
  }
  else if (processType === "judicial") 
    return GENERIC_JUDICIAL
  else if (processType === "nonJudicial")
    return GENERIC_NONJUDICIAL
  else if (processType === "chapter7") {
    return [...bkSteps.chapter7.steps]
  }
  else if (processType === "chapter13" || processType === "chapter11") {
    return [...bkSteps.chapter13and11.steps]
  }
}
const getProcessTypes = (processTypes, states) => {
  if (!processTypes || !processTypes.processTypes)
    return;

  let types = processTypes.processTypes.map((type) => (
    { name: formatProcessType(type.name), value: type.name}
  ))

  if (isJudicial(states)) {
    types = types.filter((type) => type.value !== "judicial")
  } else {
    types = types.filter((type) => type.value !== "nonJudicial")
  }
  return types;
}

export default function ReferAssetToLawFirm({ open, onClose, assets, setActiveProcess, setAsset }) {
  const [lawfirms, setLawfirms] = useState([]);
  const [selectedFirms, setSelectedFirms] = useState([]);
  const [comment, setComment] = useState("");
  const [isStartingNewProcess, setIsStartingNewProcess] = useState(setAsset ? false : true);
  const [allProcessTypes, setAllProcessTypes] = useState(null);
  const [processType, setProcessType] = useState(null);
  const [allSteps, setAllSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState(null);
  const [formData, setFormData] = useState([]);
  const [prevReferrals, setPrevReferrals] = useState([]);

  const theme = useTheme();
  const { user, portfolioID } = useContext(UserContext);
  const { addMessage } = useContext(MessageContext);

  const { data } = useQuery(GET_TEAMS_BY_USER_PORTFOLIO_ID, {
    variables: { portfolioID },
  });
  const { data: processTypes } = useQuery(GET_PROCESS_TYPES);
  const [createProcess, { loading: procLoading }] = useMutation(CREATE_PROCESS);
  const [endProcess, { loading: endProcLoading }] = useMutation(END_CURRENT_PROCESS);
  const [referPreviousProcess, { loading: refPrevLoading }] = useMutation(REFER_PREVIOUS_PROCESS, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });
  const [startReferral, { loading: refLoading }] = useMutation(START_REFERRAL_PROCESS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });

  useEffect(() => {
    if (assets.length) {
      setFormData(assets.map((asset) => (
        { id: asset.id, state: asset.state, prevProcess: asset?.activeProcess, loanNumber: asset.loanNumber }
      )));
      setPrevReferrals(assets.filter((asset) => asset?.activeProcess));

      if (processTypes) {
        setAllProcessTypes(getProcessTypes(processTypes, assets.map((asset) => asset.state)));
      }
    }
    if (data?.findTeamsByUserPortfolioId) {
      const onlyLawfirms = data.findTeamsByUserPortfolioId.filter((team) => team.type === "LAWFIRM");
      setLawfirms(onlyLawfirms);
    }

  }, [data, assets, processTypes])

  useEffect(() => {
    if (processType) {
      const steps = getSteps(processType.value, assets[0]);
      setSelectedStep(steps[1]);
      setAllSteps(steps);

      formData.forEach((asset) =>
        asset.processSteps = getSteps(processType.value, asset)
      );
    }

  }, [processType])

  const handleChange = (_event, newValue) => {
    _event.preventDefault();
    setSelectedFirms([...newValue]);
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleClose = () => {
    onClose();
    setSelectedFirms([]);
    setComment("");
    setProcessType(null);
    setFormData([]);
    setPrevReferrals([]);
    setSelectedStep(null);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFirms.length) return;

    const processPromise = isStartingNewProcess
      ? formData.map(async (asset) => {
        if (asset.prevProcess) {
          return [
            await endProcess({
              variables: {
                input: {
                  assetID: asset.id,
                  processID: asset.prevProcess.id,
                  closeDate: new Date(),
                }
              }
            }),
            await createProcess({
              variables: {
                input: {
                  asset: asset.id,
                  stepID: selectedStep.stepID,
                  startStepID: selectedStep.stepID,
                  processType: processType.value,
                  processSteps: asset.processSteps,
                  startDate: new Date(),
                }
              }
            })
          ]
        } else {
          return await createProcess({
            variables: {
              input: {
                asset: asset.id,
                stepID: selectedStep.stepID,
                startStepID: selectedStep.stepID,
                processType: processType.value,
                processSteps: asset.processSteps,
                startDate: new Date(),
              }
            }
          })
        }
      })
      : formData.map(async (asset) => {
        return await referPreviousProcess({
          variables: {
            input: {
              assetID: asset.id,
              processID: asset.prevProcess.id,
              teamID: selectedFirms.map((team) => team.id)[0],
            }
          }
        });
      });  
    const processResp = (await Promise.all(processPromise.flat())).flat();

    if (!processResp) {
      addMessage({ 
        type: "error",
        message: "Something went wrong while creating the process. Please try again later." 
      });
      return;
    }
    else if (!isStartingNewProcess) {
      addMessage({ message: "Successfully referred the selected assets!" })
      handleClose();
      return;
    }

    const referralPromise = formData.map(async (asset, i) => {
      const index = processResp.findIndex((p) => p.data.createProcess?.asset.id === asset.id);
      const data = {
        assetID: asset.id,
        portfolio: portfolioID,
        referredAsset: asset.id,
        referredProcess: processResp[index].data.createProcess.id,
        loanNumber: asset.loanNumber,
        state: asset.state,
        referralStartDate: new Date(),
        referralStatus: "PENDING",
        referredBy: user.id,
        teamID: selectedFirms.map((team) => team.id)[0],
        comment,
        statusValue: isStartingNewProcess ? selectedStep.stepID : formData[i].prevProcess.stepID,
        billingStatus: getBillingStatus(processType.value),
      };

      return await startReferral({
        variables: {
          input: {
            ...data,
          },
        },
      })
    })

    const refResp = await Promise.all(referralPromise);
    if (setActiveProcess || setAsset) {
      const activeProcess = processResp.find((p) => p.data.createProcess?.asset.id === assets[0].id).data.createProcess
        || processResp.find((p) => p.data.referPreviousProcess?.asset.id === assets[0].id).data.referPreviousProcess;

      activeProcess.referral = refResp[0].data.createReferral;
      setActiveProcess(activeProcess);
      setAsset((prev) => ({
        ...prev,
        activeProcess
      }))
    }

    if (!refResp) {
      addMessage({ 
        type: "error",
        message: "Something went wrong while referring the assets. Please try again later." 
      });
      return;
    } else {
      addMessage({ message: "Successfully referred the selected assets!" })
      handleClose();
    }
  };

  const cannotContinue = (!selectedFirms.length || !processType || !selectedStep) && isStartingNewProcess;
  const isLoading = procLoading || endProcLoading || refPrevLoading || refLoading;
  return (
    <Modal open={open} onClose={handleClose}>
      <Typography mb={5} component="h1" variant="h4" align="center">{`Refer Asset${assets.length > 1 ? "s" : ""}`}</Typography>
      <Box sx={{ backgroundColor: theme.themeColor.backgroundBody, display: "flex", flexDirection: "column" }}>
        <label>
          <strong>Selected Asset{assets?.length > 1 && 's'}: {" "}</strong>
          { assets &&
            assets.map((asset, i) => {
              return i === assets.length - 1
                ? asset.loanNumber
                : `${asset.loanNumber}, `
            }) }
        </label>
        <Autocomplete
          multiple
          className="select-lawfirm-dropdown"
          popupIcon={""}
          sx={{ width: 'auto', height: '80%', marginTop: "10px" }}
          size="small"
          options={lawfirms}
          disablePortal
          getOptionLabel={option => `${option?.name}`}
          onChange={handleChange}
          value={selectedFirms}
          renderInput={(params) => <TextInput {...params}
            size="small"
            InputProps={{
              ...params.InputProps,
            }}
            label="Select Law Firms"
          />}
        />

        <StyledInput className="asset-referral-comment" label="Comment" onChange={handleCommentChange}/>
      </Box>

      {isStartingNewProcess &&
        <Box sx={{ marginTop: "10px" }}>
          <Typography>Process Type:</Typography>
          <Select
            id='process-type-dropdown'
            value={processType && processType.value}
            name={processType && processType.name}
            inputProps={{ 'data-testid': 'id-process-dropdown-props' }}
            data-testid="id-process-dropdown"
            onChange={(_event, newVal) => {
              const { props } = newVal;
              setProcessType({
                name: props.name,
                value: props.value
              })
            }}
          >
            {allProcessTypes &&
              allProcessTypes.map((item) => {
                return (
                  <MenuItem
                    key={item.value}
                    label={item.name}
                    id={item.value}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>}

      {allSteps && isStartingNewProcess && (
        <Box sx={{ marginTop: "15px" }}>
          <Typography>Start at Milestone:</Typography>
          <Select
            id='milestone-dropdown'
            value={selectedStep && selectedStep.stepID}
            name={selectedStep && selectedStep.label}
            inputProps={{ 'data-testid': 'id-milestone-dropdown-props' }}
            data-testid="id-milestone-dropdown"
            onChange={(_event, newValue) => {
              const { props } = newValue;
              setSelectedStep({
                stepID: props.id,
                label: props.label
              })
            }}
          >
            {allSteps &&
              allSteps.map((item) => {
                return (
                  <MenuItem
                    key={item.stepID}
                    label={item.label}
                    id={item.stepID}
                    value={item.stepID}
                  >
                    {item.stepID} - {item.label}
                  </MenuItem>
                );
              })}
          </Select>
        </Box>
      )}

      {(prevReferrals.length > 0) && (
        <Box sx={{ marginTop: "15px" }}>
          <Typography>
            {prevReferrals.length > 1 ? "Assets " : "Asset "}
            <strong>{prevReferrals.map((asset, i) => i === prevReferrals.length - 1 ? asset.loanNumber : `${asset.loanNumber}, `)}</strong>
            {prevReferrals.length > 1 ? " have" : " has" } already been referred.
          </Typography>
          <Typography>
            Would you like to start a new process or keep working on the same one?
          </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "space-around"}}>
            <Checkbox
              onClick={() => setIsStartingNewProcess(true)}
              checked={isStartingNewProcess}
              label="Start New Process" />
            <Checkbox
              onClick={() => setIsStartingNewProcess(false)}
              checked={!isStartingNewProcess}
              label="Keep Old Process" />
          </Box>
        </Box>
      )}

      <Box sx={{display: 'flex', alignItems: 'center', marginTop: '50px'}}>
        <Button
          id="submit-referral-button"
          disabled={cannotContinue || isLoading}
          onClick={handleSubmit}
          sx={{
            mr: 1,
            position: 'relative',
            width: '225px'
          }}
        >
          Confirm
        </Button>

        <Button
          variant="secondary"
          sx={{ width: "25%",fontSize: '14px', border: 'none !important', background: 'inherit !important', boxShadow: 'none !important'}}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  )
}
