import React, { useState, useContext } from "react";
import Button from "../../styled/Button/Button";
import { Box } from "@mui/material";
import { REMOVE_TEAM } from "../../apollo/mutations/removeTeam";
import { useMutation } from "@apollo/client";
import SplitButton from "../../styled/SplitButton/SplitButton";
import Modal from "../../styled/Modal/Modal";
import Typography from "../../styled/Typography/Typography";
import styled from "@emotion/styled";
import { MessageContext } from "../../context/MessageContext";

const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
`

export default function ActionColumn({ value, setSelectedTeamID, setShowModal, setSelectedTeam, setSelectedTeamMembers,...rest }) {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [removeTeam] = useMutation(REMOVE_TEAM, {
    awaitRefetchQueries: true,
    refetchQueries: "active"
  });
  
  const { addMessage } = useContext(MessageContext);
  const teamID = rest.data.id;

  if (value === 'fr-create') {
    return <Button data-testid="createTeamButton" type="submit" sx={{width: '150px', height: '85%'}}>Create</Button>
  }

  const deleteTeam = async () => {
    try {
      const deleteResponse = await removeTeam({
        variables: {
          teamID,
        }
      })
      if (deleteResponse.errors){
        throw deleteResponse.errors[0].message;
      } else {
        addMessage({ message: "Team removed!" });
        rest.api.sizeColumnsToFit();
      }
    } catch(error) {
      addMessage({ message: error, type: "error" })
    }
  }

  const getOptions = () => {
    return [
      { label: "Delete", onClick: () => setOpenConfirmation(true), color: "red" }
    ]
  }

  const handleClick = () => {
    const memberships = rest.data.memberships.map((member) => ({
      ...member, teamType: rest.data.type
    }))

    setSelectedTeamID(rest.data.id)
    setSelectedTeam(rest.data)
    setShowModal(true)
    setSelectedTeamMembers(memberships)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <SplitButton 
        label="Manage"
        onClick={handleClick}
        color="ltGray"
        items={getOptions()}
      />
      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <Typography>Are you sure you want to delete this team?</Typography>

        <ButtonBox>
          <Button onClick={() => {setOpenConfirmation(false); deleteTeam();}} sx={{ width: "33%", marginRight: "30px" }}>
            Confirm
          </Button>

          <Button
            variant="secondary"
            onClick={() => setOpenConfirmation(false)}
            sx={{border: 'none !important', background: 'inherit !important', boxShadow: 'none !important', width: 100}}
          >
          Cancel
          </Button>
        </ButtonBox>
      </Modal>
    </Box>
  );
}
