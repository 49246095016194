import { gql } from "@apollo/client";
  
export const GET_REFERRAL_CONTACT_QUERY = gql`
  query GetLawfirmReferralContactEmail($state: String, $lawfirmId: ID) {
    getLawfirmReferralContactEmail(state: $state, lawfirmId: $lawfirmId) {
      state
      defaultEmail
      nonDefaultEmail
      primaryEmails
    }
  }
`;
