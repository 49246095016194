import React, { useState, useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import Modal from "../../styled/Modal/Modal";
import { MessageContext } from "../../context/MessageContext";
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { TextInput } from "../../styled/TextInput/TextInput";
import './document-delete.scss';
import { DOCUMENTS_BY_ASSET_QUERY } from "../../apollo/queries/documentsByAssetQuery";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/createTaskAndEventsMutation";
import { AssetContext } from "../../context/AssetContext";
import { EVENTS_QUERY } from "../../apollo/queries/eventsQuery";

export const DELETE_DOCUMENT = gql`
  mutation deleteDoc ($id: ID!) {
    updateDocument(input: { 
      where: { id: $id } 
      data: { deleted: true }
      }) {
        name
    }
  }
`;

export default function DocumentDelete({ showModal, setShowModal, id, docName, assetId, processID }) {
  const { currentMilestone } = useContext(AssetContext);
  const bkMilestoneID = currentMilestone?.bk?.stepID;
  const fcMilestoneID = currentMilestone?.fc?.stepID;
  
  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT, {
    refetchQueries: [
      { query: DOCUMENTS_BY_ASSET_QUERY, variables: { assetID: assetId } },
    ],
  });

  const [createEvent] = useMutation(CREATE_EVENT_MUTATION, {
    refetchQueries: [
      { query: EVENTS_QUERY, variables: { milestoneID: bkMilestoneID || fcMilestoneID, processID } },
    ]
  });

  const [userDocName, setUserDocName] = useState("");
  const { addMessage } = useContext(MessageContext);

  const onModalClose = () => {
    setShowModal(false); 
  }

  const onDocDelete = async () => {
    if (userDocName != docName){
      return;
    }

    let shouldCloseModal = false;

    // delete doc
    try {
      await deleteDocumentMutation({
        variables: { id: id },
      });
      
      addMessage({ message: 'Document deleted' });

      await createEvent({ variables: { event: {
        type: 'document-deleted',
        title: `Document deleted`,
        description: `Deleted document '${docName}'`,
        milestoneID: bkMilestoneID ? bkMilestoneID : fcMilestoneID,
        process: processID,
      }}});

      shouldCloseModal = true;
    } catch (error) {
      shouldCloseModal = false;
      addMessage({ message: error, type: "error" })
    }

    if (shouldCloseModal) {
      onModalClose();
    }
  }

  return (
    <Modal open={showModal} width="23rem" onClose={onModalClose}>
      <div className="document-delete">
        <h2>Delete Document?</h2>
        <p>Enter the document&apos;s name to confirm deleting the document: <strong>{docName}</strong></p>

        <TextInput 
          placeholder={docName}
          data-testid="text-inpt"
          className="txt-input"
          onChange={event => setUserDocName(event.target.value)} 
        />
        <p>This document will be removed from all referenced assets where it&apos;s in use.</p>

        <Box sx={{display: 'flex'}}>
          <Button data-testid="delete-btn" variant="contained" color="error" onClick={onDocDelete}>
            Delete
          </Button>
          <Button onClick={onModalClose} data-testid="cancel-btn">
            Cancel
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
