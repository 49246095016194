import { useLazyQuery } from "@apollo/client";
import React, {useState} from "react";
import { DOCUMENT_DOWNLOAD_LINK } from "../../apollo/queries/downloadLink";
import DocumentDelete from "../DocumentDelete/DocumentDelete";
import DocumentEdit from "../DocumentEdit/DocumentEdit";
import TableSettings from "../TableSettings/TableSettings";
import DocumentPreview from "../Modals/DocumentPreview";

const DocumentSettings = ({ data }) => {
  const { id, name, assetID, processID } = data;
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPreview, setShowPreview] = useState(false);


  const [
    getDownloadLink
  ] = useLazyQuery(DOCUMENT_DOWNLOAD_LINK);

  const onClickDownload = () => {
    getDownloadLink({ variables: {id: id}}).then(result => {
      const win = window.open(result.data.downloadDocumentLink.downloadLink, "_blank");
      win.focus();
    })
  }

  const items = [
    {
      label: 'Review',
      onClick: () => setShowPreview(true),
    },
    {
      label: 'Download',
      onClick: () => onClickDownload(),
    },
    {
      label: 'Edit',
      onClick: () => setShowEdit(true),
    },
    {
      label: 'Delete',
      color: 'red',
      onClick: () => setShowDelete(true),
    }
  ]
  
  return (
    <>
      <DocumentPreview open={showPreview} onClose={() => setShowPreview(false)} document={data}/>
      <DocumentDelete docName={name} id={id} showModal={showDelete} setShowModal={setShowDelete} assetId={assetID} processID={processID} />
      <DocumentEdit showEdit={showEdit} setShowEdit={setShowEdit} data={data} processID={processID} />
      <TableSettings items={items} />
    </>
    
  );
}

export default DocumentSettings;
