import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useContext, useState } from "react";
import Typography from "../../styled/Typography/Typography";
import { FaEllipsisV } from 'react-icons/fa';
import { Box, Menu, MenuItem } from "@mui/material";
import { isForeclosure } from "../../utilities";
import { AssetContext } from "../../context/AssetContext";

const Select = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 4px;
  border-radius: 20px;
  background-color: ${({theme}) => theme.themeColor.brandPrimaryBlue};
  color: ${({theme}) => theme.themeColor.backgroundBody};
  margin-left: 15px;
  margin-right: 10px;
  cursor: pointer;
`

const SelectMilestone = ({
  milestones,
}) => {
  const { currentMilestone, setCurrentMilestone, selectedProcess } = useContext(AssetContext);
  const theme = useTheme();
  const [selectAnchor, setSelectAnchor] = useState(null);
  const openOptions = Boolean(selectAnchor);

  const selectClick = (event) => {
    setSelectAnchor(event.currentTarget);
  }

  const type = isForeclosure(selectedProcess) ? "fc" : "bk";

  const onSelectClose = () => {
    setSelectAnchor(null);
  }
  const onSelectMilestone = (milestone) => {
    const copy = { ...currentMilestone };
    copy[type] = milestone;

    setCurrentMilestone(copy);
    setSelectAnchor(null);
  }

  const milestoneLabel = `${currentMilestone?.[type]?.stepID} - ${currentMilestone?.[type]?.label}`;

  return (
    <>
      <Select theme={theme} onClick={selectClick} sx={{display: "inline-block", maxWidth: "600px"}}>
        <Typography>{milestoneLabel}</Typography>
        <FaEllipsisV size={12} />
      </Select>
      <Menu
        anchorEl={selectAnchor}
        open={openOptions}
        onClose={onSelectClose}
      >
        {milestones.map((milestone) => (
          <MenuItem
            key={milestone.stepID}
            selected={milestone?.stepID === currentMilestone?.[type]?.stepID}
            onClick={() => onSelectMilestone(milestone)}
            sx={{
              fontWeight: milestone.status === "active" && "700",
              color: milestone.status === "active" && theme.themeColor.bodyMain
            }}
          >
            {`${milestone.stepID} ${milestone.label}`}
          </MenuItem>
        ))}
      </Menu>
    </>

  )
}

export default SelectMilestone;