import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Box, InputAdornment, IconButton, Backdrop, Fade, Modal as MUIModal } from '@mui/material';
import Typography from '../../styled/Typography/Typography';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {FaTimes} from 'react-icons/fa';
import Button from '../../styled/Button/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/UserContext';
import "./timeout-modal.scss";
import { REFRESH_AUTH_TOKEN } from '../../apollo/mutations/refreshAuthToken';
import { ASSIGN_REFRESH_TOKEN } from '../../apollo/mutations/assignRefreshToken';
import { parseJwt } from '../../helpers';
import { TextInput } from '../../styled/TextInput/TextInput';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

export const LOGIN_USER = gql`
  mutation loginMutation($input: UsersPermissionsLoginInput!) {
    loginMutation(input: $input) {
      jwt
      user {
        username
        email
        confirmed
        blocked
        id
      }
    }
  }
`;

export default function TimeoutModal() {
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(false);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const refresh = sessionStorage.getItem('refresh')

  const [password, setPassword] = useState("");
  const [formError, setFormError] = useState("");
  const history = useHistory();
  const client = useApolloClient();
  const { user, setUser, setPortfolioID, setTeamID } = useContext(UserContext);
  
  const [loginUser] = useMutation(LOGIN_USER);
  const [refreshToken] = useMutation(REFRESH_AUTH_TOKEN);
  const [assignRefreshToken] = useMutation(ASSIGN_REFRESH_TOKEN);

  // Used to subtract _ seconds to the current time
  // Users cannot call refreshToken if expiredTime === current time bc its expired
  // we use a buffer time of 2 mins because chrome version 88+ throttles setInterval to 1 min instead of seconds
  const BUFFER_TIME = 120;

  // Checks if auth or refresh tokens are expired
  useEffect(() => {
    const checkNeedsRefresh = () => {
      if (!user || !user.token) handleClose();

      const { token: jwt } = user;
      const { exp: authExpiredTime } = parseJwt(jwt);
      const { exp: refreshExpiredTime } = parseJwt(refresh);
      
      const dateNow = Date.now() / 1000;

      // Removes sentry error:
      // If refresh token is expired by x2 the allotted time & authtoken is expired...
      // Send user to login automatically without sending a refresh request
      // Because of this, once the modal opens and x2 the refresh exp time is reached, user is sent to login (currently 1hr)
      if (refreshExpiredTime * 2 <= dateNow && authExpiredTime < dateNow) {
        setUser(null);
        setTeamID(null);
        setPortfolioID(null);
      }

      if (refreshExpiredTime <= (dateNow + BUFFER_TIME)) {
        setUser({ ...user, timeoutOpen: true });
        setIdleTimeout(true);
      } else if (authExpiredTime < (dateNow + BUFFER_TIME)) {
        setNeedsRefresh(true);
      }
    }

    if (user.token && !refresh && !isRefreshing) {
      setUser(null);
      setTeamID(null);
      setPortfolioID(null);
    }

    const checkingToken = setInterval(() => {
      if (user.token && refresh && !isRefreshing && !idleTimeout) {
        checkNeedsRefresh();
      } else return;
    }, 1500);
    return () => clearInterval(checkingToken);
  }, [user, refresh, isRefreshing]);

  // Refreshes auth token
  useEffect(() => {
    const refresh = sessionStorage.getItem('refresh');
    async function handleRefresh() {
      if (needsRefresh) {
        await setIsRefreshing(true);
        const tokenResponse = await refreshToken({
          variables: {
            token: refresh,
            renew: idleTimeout
          }
        })
        if (tokenResponse) {
          const { data } = tokenResponse;
  
          if (data) {
            const { jwt, refresh } = data.refreshAuthToken
            setUser({ ...user, token: jwt })
            if (refresh) {
              sessionStorage.setItem('refresh', refresh);
            }

            setNeedsRefresh(false);
            setIdleTimeout(false);
            setIsRefreshing(false);
          } else {
            setUser(null);
            setTeamID(null);
            setPortfolioID(null);
          }
        }
      }
    }

    if (needsRefresh) handleRefresh();
  }, [needsRefresh, idleTimeout, refreshToken, user, setUser])

  // Toggle modal
  useEffect(() => {
    setShow(idleTimeout);
  }, [idleTimeout]);

  const handleClose = useCallback(() => {
    setShow(false);
    setIdleTimeout(false);
    setUser(null);
    setTeamID(null);
    setPortfolioID(null);
    sessionStorage.clear();
    client.clearStore();
    history.push('/auth/login');
  }, [setIdleTimeout, history, client, setUser]);

  function handleChange(event) {
    const { value } = event.target
    setPassword(value);
  }

  const handleReset = async () => {
    setIsRefreshing(true);
    const loginResponse = await loginUser({
      variables: {
        input: {
          identifier: user.email,
          password: password,
        },
      },
    });

    if (loginResponse) {
      const { data } = loginResponse;

      if (data?.loginMutation) {
        await setUser({ ...user, token: data.loginMutation.jwt, timeoutOpen: false })

        const tokenResponse = await assignRefreshToken({
          refetchQueries: 'active',
        });

        const { refresh } = tokenResponse.data.assignRefreshToken;
        await sessionStorage.setItem('refresh', refresh);
        await setShow(false);
        await setNeedsRefresh(false);
        await setIdleTimeout(false);
        await setIsRefreshing(false);
      } else {
        const errorMessage = loginResponse.errors[0]?.message;
        setFormError(errorMessage);
        handleClose();
      }
    }
  };

  return (
    <div>
      <Modal
        open={show}
      >
        <Box spacing={3}>
          <Typography id="timeout-modal-header" variant="h3" component="h1" align='center' mb={5}>Resume work</Typography>

          <Box>
            <Button id="wrong-user" variant="secondary" size="sm" onClick={handleClose}>{`Signed as ${user.firstName}. Not ${user.firstName}?`}</Button>
            <p>Your token has expired. Please, enter your password to get back to work.</p>
            <TextInput onChange={handleChange}
              sx={{ width: '100%', justifySelf: 'center'}}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>,
              }}
            />
            {formError && <ErrorMessage>{formError}</ErrorMessage>}
          </Box>

          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Button variant="primary" onClick={handleReset}>
              Continue
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Logout
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

const CloseModalArea = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const StyledModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width || "40vw"};
  height: ${(props) => props.height || 'auto'};
  border: 2px solid ${({ theme }) => theme.themeColor.sectionStroke};
  border-radius: 6px;
  boxShadow: 20;
  padding: 32px;
  background: ${({ theme }) => theme.themeColor.backgroundBody};
  outline: 0;
`

const CloseButton = styled(FaTimes)`
  cursor: pointer;
`

const Modal = (props) => {
  const { children, open, width, height, onClose } = props;
  const theme = useTheme();

  return (
    <MUIModal
      {...props}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        backdropFilter: 'blur(8px)'
      }}
      data-testid="modal-styled"
    >
      <Fade in={open}>
        <StyledModal height={height} width={width} theme={theme} data-testid="modal-body">
          {onClose && (
            <CloseModalArea>
              <CloseButton onClick={onClose} />
            </CloseModalArea>
          )}
          {children}
        </StyledModal>
      </Fade>
    </MUIModal>
  )
}
