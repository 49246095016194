import React, { useState, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import Overview from "../Foreclosure/Overview";
import Milestones from "../Milestones/Milestones";
import CreateComment from "../Comments/CreateComment";
import AddTaskAndHold from "../Milestones/AddTaskAndHold";
import styled from "@emotion/styled";
import { rgba } from "emotion-rgba";
import { useTheme } from "@emotion/react";
import FilterAssetTimeline from "../../Modals/FilterAssetTimeline";
import TLDR from "../TLDR";
import { AssetContext } from "../../../context/AssetContext";

const FloatButton = styled('div')`
  position: absolute;
  bottom: 60px;
  left: 20px;
`
const MilestonesBackground = styled('div')`
  position: absolute;
  width: 100%;
  height: 84vh;
  background: ${({theme}) => theme.themeColor.backgroundBody};
`

const TimelinePattern = styled('div')`
  position: absolute;
  background-color: transparent;  
  background: repeating-linear-gradient( 180deg, transparent, transparent 10px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 1px, ${({theme}) => rgba(theme.themeColor.bodyMain, 0.2)} 11px);
  height: 83%;
  width: 5px;
  z-index: 1;
  top: 100px;
`

export default function Bankruptcy({
  asset,
  originalFC,
  sideBarStats,
  nextStepStats,
  setCurrentMilestone,
  bkMilestones,
  setScreen,
  readOnly,
  filters,
  activeProcess,
  setActiveProcess,
  setAsset,
  client,
}) {
  const theme = useTheme();

  const { portfolioID } = useContext(UserContext);
  const { currentMilestone } = useContext(AssetContext);

  const [openTask, setOpenTask] = useState(false);
  const [openHold, setOpenHold] = useState(false);
  const [addTaskFlow, setAddTaskFlow] = useState(false);
  const [openFilterTimeline, setOpenFilterTimeline] = useState(false);
  const [timelineFilters, setTimelineFilters] = useState(filters);
  const [numFiltersChanged, setNumFiltersChanged] = useState("0");

  const hasBlockingTask = activeProcess?.tasks?.some((task) => task?.blocking && !task?.deleted && !task.completedAt)
    || asset?.activeProcess?.task_flows.some((task) => task?.blocking && !task.completedAt);

  const hasOpenHolds = activeProcess?.holds?.some((hold) => !hold?.closeDate);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Overview
          sideBarStats={nextStepStats}
          subStepStats={sideBarStats}
          asset={asset}
          setAsset={setAsset}
          client={client}
          originalFC={originalFC}
          activeProcess={activeProcess}
          setActiveProcess={setActiveProcess}
          hasBlockingTask={hasBlockingTask}
          setCurrentMilestone={setCurrentMilestone}
          hasOpenHolds={hasOpenHolds}
          readOnly={readOnly}
          setScreen={setScreen}
          milestones={bkMilestones}
          activeStep={readOnly ? readOnly.stepID : activeProcess.stepID}
          milestoneID={currentMilestone?.bk?.stepID}
        />
        <TLDR
          openFilter={setOpenFilterTimeline}
          numFiltersChanged={numFiltersChanged}
          process={readOnly ? readOnly : activeProcess}
        />
        <TimelinePattern theme={theme} />
        <MilestonesBackground theme={theme} />
        <Milestones
          process={readOnly ? readOnly : activeProcess}
          activeStep={readOnly ? readOnly.stepID : activeProcess.stepID}
          milestoneID={currentMilestone?.bk?.stepID}
          setOpenHold={setOpenHold}
          setOpenTask={setOpenTask}
          startDate={readOnly ? readOnly.startDate : activeProcess.startDate}
          currentMilestone={currentMilestone}
          setAddTaskFlow={setAddTaskFlow}
          readOnly={readOnly}
          timelineFilters={timelineFilters}
        />

        {!readOnly &&
        <>
          <CreateComment milestoneID={activeProcess.stepID} processID={activeProcess.id} />
          <FloatButton>
            <AddTaskAndHold
              asset={asset}
              substeps={bkMilestones}
              process={activeProcess}
              milestoneID={activeProcess.stepID}
              openTask={openTask}
              openHold={openHold}
              setOpenTask={setOpenTask}
              setOpenHold={setOpenHold}
              addTaskFlow={addTaskFlow}
              setAddTaskFlow={setAddTaskFlow}
              portfolioID={portfolioID}
              processType='BANKRUPTCY'
              activeProcess={activeProcess}
            />
          </FloatButton>
        </>
        }
      </div>
      <FilterAssetTimeline
        open={openFilterTimeline}
        onClose={() => setOpenFilterTimeline(false)}
        setFilters={setTimelineFilters}
        filters={timelineFilters}
        setNumFilters={setNumFiltersChanged}
      />
    </>
  )
}
